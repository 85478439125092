import { FicheAutoControleCritere, FicheAutocontroleIntervenant } from "../../store/types/FicheAutocontrole.Types";
import { Filiale, Programme, ReferentielFront, TrancheTravaux } from "../../store/types/generalInformations.Types";
import { RegroupementResponsabilite, Agence, VisiteQualite } from "../../store/types/homePageVQ.Types";

export const emptyTabInfosIntervenant: FicheAutocontroleIntervenant = {
    nom: '',
    mail: '',
    isInterne: false,
    id_gpi: ''
}

export const emptyFiliale: Filiale = {
    id_referentiel: '',
    id_param_type: '',
    id: '',
    id_gpi: '',
    libelleSociete: '',
    codeSociete: '',
    checked: true,
    actif: true,
}

export const emptyRegroupementResponsabilite: RegroupementResponsabilite = {
    id_referentiel: '',
    id_param_type: '',
    id: '',
    id_gpi: '',
    refType: '',
    libelleRegroupementResponsabilite: '',
    codeRegroupementResponsabilite: '',
    actif: true
}

export const emptyAgence: Agence = {
    id_referentiel: '',
    id_param_type: '',
    id: '',
    id_gpi: '',
    refType: '',
    codeAgence: '',
    libelleAgence: '',
    codeRegroupementResponsabilite: '',
    actif: true
}

export const defaultAgence: Agence = {
    id_referentiel: 'default',
    id_param_type: '',
    id: '',
    id_gpi: '',
    refType: '',
    codeAgence: '',
    libelleAgence: 'TOUTES',
    codeRegroupementResponsabilite: '',
    actif: true
}

export const emptyProgramme: Programme = {
    regroupementResp: '',
    id_referentiel: '',
    id_param_type: '',
    id: '',
    id_gpi: '',
    libelleProgramme: '',
    libelleCommercial: '',
    codeProgramme: '',
    codeSociete: '',
    actif: true,
    adresse1: '',
    adresse2: '',
    adresse3: '',
    ville: '',
    codePostal: '',
}

export const emptyTrancheTravaux: TrancheTravaux = {
    id_referentiel: '',
    id_param_type: '',
    id: '',
    id_gpi: '',
    codeSociete: '',
    codeProgramme: '',
    codePhase: '',
    libelleTranche: '',
    codeTranche: '',
    nbLots: 0,
    dateLivraisonPrevue: 0,
    actif: true,
}

export const emptyReferentielFront: ReferentielFront = {
    id: '',
    value: ''
}

export const getEmptyCheckboxCritere = () => {
    let critereChekbox: FicheAutoControleCritere = {
        attachedPageIndex: undefined,
        displayWhenValue: undefined,
        attachedElementIndex: undefined,
        title: '',
        index: 0,
        active: false,
        activated: true,
        typeCriteria: 'CB',
        value: [],
        choices: [''],
        weightingActor: false,
        weighting: 0,
        options: ''
    }

    return critereChekbox;
}

export const getEmptySelectCritere = () => {
    let critereSelect: FicheAutoControleCritere = {
        attachedPageIndex: undefined,
        displayWhenValue: undefined,
        attachedElementIndex: undefined,
        title: '',
        index: 0,
        active: false,
        activated: true,
        typeCriteria: 'SL',
        value: '',
        choices: [''],
        weightingActor: false,
        weighting: 0,
        options: ''
    }

    return critereSelect;
}

export const getEmptyDateCritere = () => {
    let critereDate: FicheAutoControleCritere = {
        attachedPageIndex: undefined,
        displayWhenValue: undefined,
        attachedElementIndex: undefined,
        dateMin: undefined,
        dateMax: undefined,
        title: '',
        index: 0,
        active: false,
        activated: true,
        typeCriteria: 'DA',
        value: 0,
        weightingActor: false,
        weighting: 0
    }

    return critereDate;
}

export const getEmptyPeriodeCritere = () => {
    let critereDate: FicheAutoControleCritere = {
        attachedPageIndex: undefined,
        displayWhenValue: undefined,
        attachedElementIndex: undefined,
        dateMin: undefined,
        dateMax: undefined,
        title: '',
        index: 0,
        active: false,
        activated: true,
        typeCriteria: 'PSC',
        value: 0,
        weightingActor: false,
        weighting: 0
    }

    return critereDate;
}

export const emptyVisiteQualite = (type: string) => {
    let visite: VisiteQualite = {
        id_referentiel: '',
        id: '',
        id_param_type: '',
        id_gpi: '',
        refType: '',
        typeVisiteQualite: type,
        datePlanificationVisite: 0,
        id_tranche_visite: '',
        responsableProgramme: emptyTabInfosIntervenant,
        conducteurTravaux: emptyTabInfosIntervenant,
        chargeMissionQualite: emptyTabInfosIntervenant,
        commentaire: ''
    }
    return visite;
}

export const emptyCMQTerrainSelect : string = 'NOSELECTION';

