import { Reducer } from "redux";
import { emptyFiliale, emptyProgramme, emptyTabInfosIntervenant, emptyTrancheTravaux, emptyCMQTerrainSelect } from "../../utils/constantes/emptyObjects";
import { AUTOCONTROLE_STATUT, GeneralInformationsState, GeneralInformationsTypes } from "../types/generalInformations.Types";

export const initialState: GeneralInformationsState={
    filiale: emptyFiliale,
    programme:  emptyProgramme,
    trancheTravaux:  emptyTrancheTravaux,
    typeAutocontrole: '',
    responsableProgramme : emptyTabInfosIntervenant,
    conducteurTravaux : emptyTabInfosIntervenant,
    chargeMissionQualite : emptyTabInfosIntervenant,
    responsableTechnique: emptyTabInfosIntervenant,
    directeurOperationnel:emptyTabInfosIntervenant,
    directeurProgrammes: emptyTabInfosIntervenant,
    ficheStatut: AUTOCONTROLE_STATUT.FERME,
    dateLimite: 0,
    dateLivraison: 0,
    isDisplayed: false,
    nbLots: 0,
    editBy: emptyCMQTerrainSelect
}

const reducer: Reducer<GeneralInformationsState> = (state = initialState, action = {type:'', payload:''}) => {
    switch(action.type){
        case GeneralInformationsTypes.SET_GENERAL_INFORMATIONS:{
            let copy = {...state};
            copy = action.payload;
            return {...copy}
        }
        case GeneralInformationsTypes.CHANGE_TYPE_AUTOCONTROLE:{
            return initialState
        }
        default:{
            return state;
        }
    }
}

export {reducer as GeneralInformationsReducer};